<template>
  <div>
    <div class="d-flex justify-end align-end mb-4" style="background: #f8f8f8">
      <v-btn color="primary" depressed to="e-learning-form"
        >Buat E-Learning</v-btn
      >
    </div>
    <v-data-table
      :headers="headers"
      :items="data"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      item-key="id"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          color="primary"
          fab
          depressed
          x-small
          @click="toElearningForm(item)"
          ><v-icon small>mdi-pencil-box-outline</v-icon></v-btn
        >
        <v-btn
          color="info"
          fab
          depressed
          x-small
          class="mx-2"
          @click="toElearningDetail(item)"
          ><v-icon small>mdi-eye</v-icon></v-btn
        >
        <v-btn color="error" fab depressed x-small @click="deleteHandler(item)"
          ><v-icon small>mdi-delete-forever</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <ModalConfirm
      :dialog="dialogConfrim"
      :text="`Hapus elearning ${deleteData && deleteData.title}?`"
      title="Hapus Elearning"
      @action="(r) => deleteAction(r)"
    />
  </div>
</template>

<script>
import { getElearning, deleteElearning } from "@/api/elearning";

export default {
  data() {
    return {
      loading: false,
      dialogConfrim: false,
      selected: [],
      data: [],
      selectedData: {},
      deleteData: null,
      options: {},
      total: 0,
      query: {
        search: "",
        limit: 5,
        page: 1,
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Judul",
          value: "title",
        },
        {
          text: "Mata Pelajaran",
          value: "subject",
        },
        {
          text: "Aksi",
          value: "action",
          align: "center",
          width: 200,
        },
      ];
    },
  },
  created() {
    this.getElearningHandler();
  },
  watch: {
    options: {
      handler(param) {
        this.query.page = param.page;
        this.query.limit = param.itemsPerPage;

        this.getElearningHandler();
      },
      deep: true,
    },
  },
  methods: {
    getElearningHandler() {
      this.loading = true;
      getElearning()
        .then((res) => {
          if (res.data.code) {
            this.data = res.data.data.data;
            this.total = res.data.data.total;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    deleteHandler(item) {
      this.deleteData = item;
      this.dialogConfrim = true;
    },
    deleteAction(param) {
      if (param) {
        this.$store.commit("SET_GLOBAL_LOADING", true);
        deleteElearning({ id: this.deleteData.id })
          .then((res) => {
            if (res.data.code) {
              this.$store.commit("SET_SNACKBAR", {
                value: true,
                text: "Berhasil menghapus elearning",
                color: "success",
              });
              this.getElearningHandler();
              this.deleteData = null;
              this.dialogConfrim = false;
            } else {
              this.$store.commit("SET_SNACKBAR", {
                value: true,
                text: res.data.message,
                color: "error",
              });
            }
            this.$store.commit("SET_GLOBAL_LOADING", false);
          })
          .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));
      } else {
        this.dialogConfrim = false;
      }
    },
    toElearningForm(item) {
      this.$router.push(`e-learning-form?id=${item.id}`);
    },
    toElearningDetail(item) {
      this.$router.push(`e-learning-detail?id=${item.id}`);
    },
  },
};
</script>
